import logo from './jim.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          국가 반란 행위 "윤석열" "국민의 힘" "검찰"등 에 대한 정보 제공 및 공유를 위한 사이트 입니다.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          곧 오픈 합니다!
        </a>
      </header>
    </div>
  );
}

export default App;
